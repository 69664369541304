<script setup lang="ts">
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'
import type { StoreDetailsSidebarProps } from '@design-system/components/Checkout/CheckoutStoreDetailsSidebar.props'

defineProps<StoreDetailsSidebarProps>()
const { checkoutStoreDetailsSidebar } = SIDEBAR_IDS
</script>
<template>
  <div>
    <OrganismsSidebarSlide
      :id="checkoutStoreDetailsSidebar"
      :is-alpha-layer-transparent="isAlphaLayerTransparent"
      :header-props="{
        titleText: 'Store details',
        hasBackButton: true,
        hasCloseButton: false,
      }"
    >
      <template #body>
        <CheckoutStoreDetail :store="selectedStoreDetails" />
      </template>
    </OrganismsSidebarSlide>
  </div>
</template>
