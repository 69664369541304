<script setup lang="ts">
import type { StoreLocatorMap } from './AtomsStoreLocatorMap.props'
import { promiseTimeout } from '@vueuse/core'

const props = defineProps<StoreLocatorMap>()
const emit = defineEmits<{
  onMarkerClick: [marker: google.maps.Marker]
  onMarkerMouseOver: []
  onMarkerMouseOut: []
}>()

const { setCenterMap } = useStoreLocator()

const googleMapsRef = ref()
const markersInstances = ref<google.maps.Marker[]>([])

const computedCenter = computed<Coordinates>(
  () => props.center ?? defaultMapCenter
)

const resetMarkersIcon = () => {
  markersInstances.value.forEach(marker => {
    const markerId = marker.get('id')
    const markerIcon = props.markers?.find(
      ({ id }) => id === markerId
    )?.customIcon
    marker.setIcon(markerIcon ?? customIcons.markerIcon)
  })
}

const setMarkerListeners = (marker: google.maps.Marker) => {
  const handleClick = async () => {
    const { activeMarkerIcon } = customIcons
    const positionMarker = marker.getPosition()

    if (positionMarker) {
      setCenterMap({
        lat: positionMarker.lat(),
        lng: positionMarker.lng(),
      })
    }

    resetMarkersIcon()
    marker.setIcon(activeMarkerIcon)
    await promiseTimeout(300)
    emit('onMarkerClick', marker)
  }

  marker.addListener('click', handleClick)
  marker.addListener('mouseover', () => emit('onMarkerMouseOver'))
  marker.addListener('mouseout', () => emit('onMarkerMouseOut'))
}

const addMarkers = async () => {
  const api = googleMapsRef.value
  const googleMaps = api.googleMaps
  const map = api.map

  await api.importLibrary('marker')
  props.markers?.forEach(({ id, name, position, customIcon }) => {
    if (googleMaps) {
      const marker = new googleMaps.Marker({
        id,
        map,
        icon: customIcon ?? customIcons.markerIcon,
        title: name,
        position,
      })
      if (marker) {
        markersInstances.value.push(marker)
        setMarkerListeners(marker)
      }
    }
  })
}

const clearMarkers = () => {
  if (!markersInstances.value.length) return
  markersInstances.value.forEach(marker => {
    marker.setVisible(false)
    marker.setMap(null)
    marker.setPosition(null)
  })
  markersInstances.value = []
}

watch(
  () => props.markers,
  () => {
    clearMarkers()
    addMarkers()
  }
)

onBeforeUnmount(clearMarkers)
</script>

<template>
  <ScriptGoogleMaps
    ref="googleMapsRef"
    :center="computedCenter"
    :api-key="useRuntimeConfig().public.google"
    :map-options="{
      mapId: '',
      styles: mapStyle,
      zoom: 10,
      zoomControl: true,
      fullscreenControl: false,
      scaleControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      clickableIcons: true,
    }"
    :center-marker="false"
    @ready="addMarkers"
  />
</template>
