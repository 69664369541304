export type Coordinates = google.maps.LatLngLiteral

export const mapStyle: google.maps.MapTypeStyle[] = []

export const defaultMapCenter = { lat: 45.47061, lng: 9.19283 }

export const customIcons = {
  markerIcon: '/images/map/pinGeneric.svg',
  activeMarkerIcon: '/images/map/pinGeneric.svg',
}

export const getCoordinates = (
  l?: Partial<Coordinates>,
  fuzzy?: boolean
): Coordinates => {
  const lat = l?.lat ?? 0
  const lng = l?.lng ?? 0

  return fuzzy ? fuzzyCoordinate({ lat, lng }, 10) : { lat, lng }
}

const fuzzyCoordinate = (center: Coordinates, radius: number): Coordinates => {
  const lon = center.lng
  const lat = center.lat

  //Earth’s radius
  const R = 6378137

  const distanceNorth = Math.random() * radius + 2
  const distanceEast = Math.random() * radius + 2

  const dLat = distanceNorth / R
  const dLon = distanceEast / (R * Math.cos((Math.PI * lat) / 180))
  // Resulting point.
  return {
    lat: lat + (dLat * 180) / Math.PI,
    lng: lon + (dLon * 180) / Math.PI,
  }
}

export const getCountryMapCenter = (code: string) => {
  const countryMapCenters: Record<string, Coordinates> = {
    at: { lat: 48.20849, lng: 16.37208 },
    au: { lat: -35.28346, lng: 149.12807 },
    be: { lat: 50.85045, lng: 4.34878 },
    bg: { lat: 42.69751, lng: 23.32415 },
    ca: { lat: 45.41117, lng: -75.69812 },
    ch: { lat: 46.94809, lng: 7.44744 },
    cl: { lat: -33.45694, lng: -70.64827 },
    cz: { lat: 50.08804, lng: 14.42076 },
    de: { lat: 52.52437, lng: 13.41053 },
    dk: { lat: 55.67594, lng: 12.56553 },
    ee: { lat: 59.43696, lng: 24.75353 },
    es: { lat: 40.4165, lng: -3.70256 },
    fi: { lat: 60.16952, lng: 24.93545 },
    fr: { lat: 48.8534, lng: 2.3486 },
    gb: { lat: 51.50853, lng: -0.12574 },
    gr: { lat: 37.98376, lng: 23.72784 },
    hk: { lat: 22.29763, lng: 114.1691 },
    hr: { lat: 45.81444, lng: 15.97798 },
    hu: { lat: 47.49835, lng: 19.04045 },
    id: { lat: -6.19481, lng: 106.82304 },
    ie: { lat: 53.33306, lng: -6.24889 },
    il: { lat: 31.76904, lng: 35.21633 },
    it: { lat: 45.47061, lng: 9.19283 },
    jp: { lat: 35.6895, lng: 139.69171 },
    kr: { lat: 37.566, lng: 126.9784 },
    lt: { lat: 54.68916, lng: 25.2798 },
    lu: { lat: 49.61167, lng: 6.13 },
    lv: { lat: 56.946, lng: 24.10589 },
    my: { lat: 3.1412, lng: 101.68653 },
    nl: { lat: 52.37403, lng: 4.88969 },
    nz: { lat: -41.28664, lng: 174.77557 },
    ph: { lat: 14.6042, lng: 120.9822 },
    pl: { lat: 52.22977, lng: 21.01178 },
    pt: { lat: 38.72511, lng: -9.14947 },
    ro: { lat: 44.43225, lng: 26.10626 },
    se: { lat: 59.33258, lng: 18.0649 },
    sg: { lat: 1.282963, lng: 103.85966 },
    si: { lat: 46.05108, lng: 14.50513 },
    sk: { lat: 48.14816, lng: 17.10674 },
    th: { lat: 13.75398, lng: 100.50144 },
    tr: { lat: 39.90959, lng: 32.77744 },
    tw: { lat: 25.04776, lng: 121.53185 },
    us: { lat: 38.89793, lng: -77.03307 },
    za: { lat: -25.74486, lng: 28.18783 },
    wx: { lat: 45.47061, lng: 9.19283 },
  }
  return countryMapCenters[code.toLowerCase()] ?? defaultMapCenter
}

export const countriesUsingMph = ['us', 'gb']
