<script setup lang="ts">
import type { CheckoutStoreDetailProps } from './CheckoutStoreDetail.props'

const props = defineProps<CheckoutStoreDetailProps>()

const { getIcon } = useStoreLocator()

const defaultStoreEmail = 'armani@armani.com'

const showStoreDetailsAddress = computed(() => {
  const { line1, postalCode, city, region, countryCode } = props.store.address
  return `${line1}, ${postalCode} ${city} ${region} ${countryCode}` ?? ''
})

const storeCoordinates = computed(() =>
  getCoordinates({
    lat: props.store.yextDisplayCoordinate?.latitude,
    lng: props.store.yextDisplayCoordinate?.longitude,
  })
)
</script>

<template>
  <div class="gap-lg mb-[120px] flex flex-col">
    <div class="flex flex-col gap-6">
      <span class="text-medium-4">
        {{ store.c_internalName }}
      </span>
      <div class="flex flex-1 items-center">
        <AtomsStoreLocatorMap
          :center="storeCoordinates"
          :markers="[
            {
              id: store.c_internalName,
              position: storeCoordinates,
            },
          ]"
          class="aspect-3/2 w-full"
        />
      </div>
    </div>
    <div class="gap-xs flex flex-col">
      <span class="text-light-6 text-primitives-grey-200">{{
        $ts('checkout.shipping.storeDetails.address')
      }}</span>
      <div>
        <p class="text-book-6">
          {{ store.c_internalName }}
        </p>
        <span class="text-book-6">{{ showStoreDetailsAddress }} </span>
      </div>
    </div>
    <div class="gap-xs flex flex-col">
      <span class="text-light-6 text-primitives-grey-200">
        {{ $ts('checkout.shipping.storeDetails.phone') }}
      </span>
      <span class="text-book-6">
        {{ store?.mainPhone }}
      </span>
    </div>
    <div class="gap-xs flex flex-col">
      <span class="text-light-6 text-primitives-grey-200">{{
        $ts('checkout.shipping.storeDetails.email')
      }}</span>
      <span class="text-book-6">
        {{ store.emails ? store?.emails[0] : defaultStoreEmail }}
      </span>
    </div>
    <ul v-if="store.hours" class="gap-xs flex flex-col">
      <span class="text-light-6 text-primitives-grey-200">
        {{ $ts('checkout.shipping.storeDetails.opening') }}
      </span>
      <li
        v-for="[key, value] in Object.entries<Record<string, any>>(store.hours)"
        :key="key"
        class="flex justify-between"
      >
        <span class="text-book-6 capitalize">{{ key }}</span>
        <span class="text-book-6">
          {{
            value?.openIntervals?.[0]?.start && value?.openIntervals?.[0]?.end
              ? `${value?.openIntervals?.[0]?.start} - ${value?.openIntervals?.[0]?.end}`
              : 'Closed'
          }}
          <span v-if="value?.openIntervals?.[1]" class="text-book-6">
            |
            {{
              value?.openIntervals?.[1].start && value?.openIntervals?.[1].end
                ? `${value?.openIntervals?.[1]?.start} - ${value?.openIntervals?.[1]?.end}`
                : $ts('checkout.pickup.closed')
            }}
          </span>
        </span>
      </li>
    </ul>
  </div>
</template>
