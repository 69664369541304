export interface Interval {
  start: any
  end: any
}

export interface DayHour {
  openIntervals?: Interval[]
  isClosed?: boolean
}

export interface HolidayHours {
  date: string
  openIntervals?: Interval[]
  isClosed?: boolean
  isRegularHours?: boolean
}

export interface Hours {
  monday?: DayHour
  tuesday?: DayHour
  wednesday?: DayHour
  thursday?: DayHour
  friday?: DayHour
  saturday?: DayHour
  sunday?: DayHour
  holidayHours?: HolidayHours[]
  reopenDate?: string
}

export enum Category {
  BOOK_TRAVEL = 'Book Travel',
  CHECK_IN = 'Check in',
  FEES_POLICIES = 'Fees Policies - Deprecated',
  FLIGHT_STATUS = 'Flight Status',
  TICKETS = 'Tickets',
  TICKETING = 'Ticketing - Deprecated',
  AMENITIES = 'Amenities',
  FRONT_DESK = 'Front Desk - Deprecated',
  PARKING = 'Parking',
  GIFT_CARD = 'Gift Card',
  WAITLIST = 'Waitlist',
  DELIVERY = 'Delivery (Restaurant)',
  ORDER = 'Order (Restaurant)',
  TAKEOUT = 'Takeout - Deprecated',
  PICKUP = 'Pickup (Restaurant)',
  RESERVE = 'Reserve (Restaurant)',
  MENU = 'Menu',
  APPOINTMENT = 'Appointment - Deprecated',
  PORTFOLIO = 'Portfolio - Deprecated',
  QUOTE = 'Quote',
  SERVICES = 'Services',
  STORE_ORDERS = 'Store Orders - Deprecated',
  STORE_SHOP = 'Store Shop - Deprecated',
  STORE_SUPPORT = 'Store Support - Deprecated',
  SCHEDULE = 'Schedule',
  SHOWTIMES = 'Showtimes',
  AVAILABILITY = 'Availability',
  PRICING = 'Pricing',
  ACTIVITIES = 'Activities',
  BOOK = 'Book',
  BOOK__HOTEL_ = 'Book (Hotel)',
  BOOK__RIDE_ = 'Book Ride',
  BOOK__TOUR_ = 'Book Tour',
  CAREERS = 'Careers',
  CHARGE = 'Charge',
  COUPONS = 'Coupons',
  DELIVERY__RETAIL_ = 'Delivery (Retail)',
  DONATE = 'Donate',
  EVENTS = 'Events',
  ORDER__RETAIL_ = 'Order (Retail)',
  OTHER_MENU = 'Other Menu - Deprecated',
  PICKUP__RETAIL_ = 'Pickup (Retail)',
  RESERVE__PARKING_ = 'Reserve (Parking)',
  SHOWS = 'Shows',
  SPORTS = 'Sports',
  SUPPORT = 'Support',
  TEE_TIME = 'Tee Time',
  GIFT_CARD__RESTAURANT_ = 'Gift Card (Restaurant) - Deprecated',
}

export interface AppleActionLinks {
  category: Category
  appStoreUrl: string
  quickLinkUrl: string
  appName: string
}

export interface ImageThumbnail {
  url: string
  width: number
  height: number
}

export interface Image {
  url: string
  width: number
  height: number
  thumbnails?: ImageThumbnail[]
  alternateText?: string
}

export interface EntityReference {
  entityId: string
  name: string
}

export enum BingRelationshipType {
  LOCATED_IN = 'Located In',
  DEPARTMENT_OF = 'Department Of',
  WORKS_AT = 'Works At',
}

export enum PickupAndDeliveryServices {
  IN_STORE_PICKUP = 'In-Store Pickup',
  CURBSIDE_PICKUP = 'Curbside Pickup',
  PICKUP_NOT_OFFERED = 'Pickup Not Offered',
  DELIVERY = 'Delivery',
  SAME_DAY_DELIVERY = 'Same Day Delivery',
  NO_CONTACT_DELIVERY = 'No-Contact Delivery',
  DELIVERY_NOT_OFFERED = 'Delivery Not Offered',
}

export interface Address {
  line1?: string
  line2?: string
  line3?: string
  sublocality?: string
  city?: string
  region?: string
  postalCode?: string
  extraDescription?: string
  countryCode?: string
}

export interface ComplexImage {
  image: Image
  details?: string
  description?: string
  clickthroughUrl?: string
}

export interface Coordinate {
  latitude?: number
  longitude?: number
}

export enum LinkType {
  OTHER = 'Other',
  URL = 'URL',
  PHONE = 'Phone',
  EMAIL = 'Email',
}

export interface C_aboutSectionCTA {
  label?: string
  linkType?: LinkType
  link?: string
}

export enum C_locationBrand {
  ARMANI = 'Armani',
  GIORGIO_ARMANI = 'Giorgio Armani',
  EMPORIO_ARMANI = 'Emporio Armani',
  ARMANI_CASA = 'Armani/Casa',
  EA7 = 'EA7',
  EMPORIO_ARMANI_JUNIOR = 'Emporio Armani Junior',
  ARMANI_HOTELS = 'Armani Hotels',
  ARMANI_FIORI = 'Armani/Fiori',
  ARMANI_DOLCI = 'Armani/Dolci',
  RESTAURANTS___CAFES = 'Restaurants, Cafes and Clubs',
  ARMANI_SILOS = 'Armani/Silos',
  ARMANI_LIBRI = 'Armani/Libri',
  AX_ARMANI_EXCHANGE = 'AX Armani Exchange',
  ARMANI_OUTLET = 'Armani Outlet',
}

export enum C_armaniSite {
  ARMANI = 'Armani',
  ARMANI_EXCHANGE = 'Armani Exchange',
}

export interface ServiceButton {
  label?: string
  linkType?: LinkType
  link?: string
}

export interface C_bookAnAppointment1 {
  serviceHeading?: string
  serviceDescription?: string
  serviceImage?: Image
  serviceButton?: ServiceButton
}

export interface CollectionButtons {
  text: string
  uRL: string
}

export interface C_collections {
  collectionHeading?: string
  collectionDescription?: string
  collectionButtons?: CollectionButtons[]
  collectionImage?: Image
}

export enum C_directoryContinent {
  AMERICAS = 'Americas',
  ASIA_PACIFIC = 'Asia Pacific',
  EUROPE = 'Europe',
  MIDDLE_EAST___AFRICA = 'Middle East & Africa',
}

export interface C_exchangeInStore1 {
  serviceHeading?: string
  serviceDescription?: string
  serviceImage?: Image
  serviceButton?: ServiceButton
}

export enum C_folderBrand {
  ARMANI = 'Armani',
  GIORGIO_ARMANI = 'Giorgio Armani',
  EMPORIO_ARMANI = 'Emporio Armani',
  EMPORIO_ARMANI_JUNIOR = 'Emporio Armani Junior',
  EA7 = 'EA7',
  ARMANI_EXCHANGE = 'Armani Exchange',
  ARMANI_RESTAURATS___CAFES = 'Armani Restaurants, Cafes and Clubs',
  ARMANI_HOTELS = 'Armani Hotels',
  ARMANI_FIORI = 'Armani/Fiori',
  ARMANI_CASA = 'Armani/Casa',
  ARMANI_DOLCI = 'Armani/Dolci',
  ARMANI_SILOS = 'Armani/Silos',
  ARMANI_LIBRI = 'Armani/Libri',
  ARMANI_OUTLET = 'Armani Outlet',
}

export interface C_lINE {
  label?: string
  linkType?: LinkType
  link?: string
}

export enum C_locationBrands {
  ARMANI = 'Armani',
  GIORGIO_ARMANI = 'Giorgio Armani',
  EMPORIO_ARMANI = 'Emporio Armani',
  EMPORIO_ARMANI_JUNIOR = 'Emporio Armani Junior',
  EA7 = 'EA7',
  ARMANI_CASA = 'Armani/Casa',
  ARMANI_DOLCI = 'Armani/Dolci',
  ARMANI_FIORI = 'Armani/Fiori',
  ARMANI_LIBRI = 'Armani/Libri',
  ARMANI_SILOS = 'Armani/Silos',
  RESTAURANTS___CAFES = 'Restaurants & Cafes',
  ARMANI_HOTELS = 'Armani Hotels',
}

export enum C_locationCategories {
  ACCESSORIES = 'Accessories',
  BOOKS = 'Books',
  CHOCOLATES_AND_PATISSERIE = 'Chocolates and patisserie',
  CLOTHING = 'Clothing',
  FLOWER_DECORATIONS = 'Flower decorations',
  FRAGRANCES = 'Fragrances',
  FURNITURE_AND_DECOR = 'Furniture and decor',
  JEWELRY = 'Jewelry',
  LEATHER_GOODS = 'Leather goods',
  MAKEUP = 'Makeup',
  MUSIC = 'Music',
  SHOES = 'Shoes',
  SKINCARE = 'Skincare',
  SOFT_ACCESSORIES = 'Soft accessories',
  SUNGLASSES = 'Sunglasses',
  SWIMWEAR = 'Swimwear',
  UNDERWEAR = 'Underwear',
  WATCHES = 'Watches',
  FOOD_AND_BEVERAGE = 'Food and beverage',
}

export enum C_locationCategoriesAX {
  ACCESSORIES = 'Accessories',
  CLOTHING = 'Clothing',
  FRAGRANCES = 'Fragrances',
  JEWELRY = 'Jewelry',
  LEATHER_GOODS = 'Leather goods',
  SHOES = 'Shoes',
  SOFT_ACCESSORIES = 'Soft accessories',
  SUNGLASSES = 'Sunglasses',
  SWIMWEAR = 'Swimwear',
  UNDERWEAR = 'Underwear',
  WATCHES = 'Watches',
}

export enum C_locationGender {
  MEN = 'Men',
  WOMEN = 'Women',
  KIDS = 'Kids',
}

export enum C_locationGenderAX {
  MEN = 'Men',
  WOMEN = 'Women',
}

export enum C_locationServices {
  MADE_TO_MEASURE = 'Made to measure',
  PICK_UP_IN_STORE = 'Pick-up in store',
  RETURN_IN_STORE = 'Return in store',
  EXCHANGE_IN_STORE = 'Exchange in store',
  BOOK_AN_APPOINTMENT = 'Book an appointment',
  ACCESSIBILITY_FEATURES = 'Accessibility features',
  BIKE_DELIVERY = 'Bike delivery',
  COFFEE_STATION = 'Coffee station',
  DIGITAL_WALL = 'Digital Wall',
  GIFT_CARDS_ACCEPTED = 'Gift cards accepted',
  GUEST_WI_FI = 'Guest Wi-Fi',
  HOME_DELIVERY = 'Home delivery',
  LOCKER = 'Locker',
  PARKING_FACILITIES = 'Parking facilities',
  PRODUCT_PERSONALIZATION = 'Product personalization',
  SANITARY_COUNTERMEASURES = 'Sanitary countermeasures',
  SMART_FITTING_ROOMS = 'Smart fitting rooms',
  TAILORING = 'Tailoring',
}

export enum C_locationServicesAX {
  PICK_UP_IN_STORE = 'Pick-up in store',
  RETURN_IN_STORE = 'Return in store',
  EXCHANGE_IN_STORE = 'Exchange in store',
  BOOK_AN_APPOINTMENT = 'Book an appointment',
  ACCESSIBILITY_FEATURES = 'Accessibility features',
  BIKE_DELIVERY = 'Bike delivery',
  COFFEE_STATION = 'Coffee station',
  DIGITAL_WALL = 'Digital Wall',
  GIFT_CARDS_ACCEPTED = 'Gift cards accepted',
  GUEST_WIFI = 'Guest Wi-Fi',
  LOCKER = 'Locker',
  PARKING_FACILITIES = 'Parking facilities',
  PRODUCT_PERSONALIZATION = 'Product personalization',
  SANITARY_COUNTERMEASURES = 'Sanitary countermeasures',
  SMART_FITTING_ROOMS = 'Smart fitting rooms',
  TAILORING = 'Tailoring',
}

export interface C_pickUpInStore1 {
  serviceHeading?: string
  serviceDescription?: string
  serviceImage?: Image
  serviceButton?: ServiceButton
}

export interface C_primaryCTA1 {
  label?: string
  linkType?: LinkType
  link?: string
}

export interface C_primaryCTA2 {
  label?: string
  linkType?: LinkType
  link?: string
}

export interface C_primaryCTA3 {
  label?: string
  linkType?: LinkType
  link?: string
}

export interface PromoButton {
  label?: string
  linkType?: LinkType
  link?: string
}

export interface C_promos {
  promoHeading?: string
  promoSubheading?: string
  promoBody?: string
  promoButton?: PromoButton
  images?: Image[]
  anchorLinkText?: string
}

export interface C_returnInStore1 {
  serviceHeading?: string
  serviceDescription?: string
  serviceImage?: Image
  serviceButton?: ServiceButton
}

export enum C_storeRelationship {
  DMS = 'DMS',
  CONCESSION = 'Concession',
  WHOLESALE = 'Wholesale',
}

export interface C_temporaryClosureDates {
  startDate?: string
  endDate?: string
}

export interface C_whatsApp {
  label?: string
  linkType?: LinkType
  link?: string
}

export interface FeaturedMessage {
  description?: string
  url?: string
}

export enum LocationType {
  LOCATION = 'Location',
  HEALTHCARE_FACILITY = 'Healthcare Facility',
  HEALTHCARE_PROFESSIONAL = 'Healthcare Professional',
  ATM = 'ATM',
  RESTAURANT = 'Restaurant',
  HOTEL = 'Hotel',
}

export interface MenuUrl {
  url?: string
  displayUrl?: string
  preferDisplayUrl?: boolean
}

export interface OrderUrl {
  url?: string
  displayUrl?: string
  preferDisplayUrl?: boolean
}

export enum PaymentOptions {
  AFTERPAY = 'Afterpay',
  ALIPAY = 'Alipay',
  AMERICANEXPRESS = 'American Express',
  ANDROIDPAY = 'Google Pay',
  APPLEPAY = 'Apple Pay',
  ATM = 'ATM',
  ATMQUICK = 'ATM Quick',
  BACS = 'BACS',
  BANCONTACT = 'Bancontact',
  BANKDEPOSIT = 'Bank Deposit',
  BANKPAY = 'Bank Pay',
  BGO = 'Bank/Giro Overschrijving',
  BITCOIN = 'Bitcoin',
  Bar = 'Bargeld',
  CARTASI = 'CartaSi',
  CASH = 'Cash',
  CCS = 'CCS',
  CHECK = 'Check',
  CONB = 'Contactloos betalen',
  CONTACTLESSPAYME = 'Contactless Payment',
  CVVV = 'Cadeaubon/VVV bon',
  DEBITNOTE = 'Debit Note',
  DINERSCLUB = 'Diners Club',
  DIRECTDEBIT = 'Direct Debit',
  DISCOVER = 'Discover',
  ECKARTE = 'Girokarte',
  ECOCHEQUE = 'EcoCheque',
  EKENA = 'E-kena',
  EMV = 'Elektronische Maaltijdcheques',
  FINANCING = 'Financing',
  GOPAY = 'GoPay',
  HAYAKAKEN = 'Hayakaken',
  HEBAG = 'He-Bag',
  IBOD = 'iBOD',
  ICCARDS = 'IC Cards',
  ICOCA = 'Icoca',
  ID = 'iD',
  IDEAL = 'iDeal',
  INCA = 'Incasso',
  INVOICE = 'Invoice',
  JCB = 'JCB',
  JCoinPay = 'J−Coin Pay',
  JKOPAY = 'JKO Pay',
  KITACA = 'Kitaca',
  KLA = 'Klantenkaart',
  KLARNA = 'Klarna',
  LINEPAY = 'LINE Pay',
  MAESTRO = 'Maestro',
  MANACA = 'Manaca',
  MASTERCARD = 'MasterCard',
  MIPAY = 'Mi Pay',
  MONIZZE = 'Monizze',
  MPAY = 'MPay',
  Manuelle_Lastsch = 'Manuelle Lastschrift',
  Merpay = 'メルPay',
  NANACO = 'nanaco',
  NEXI = 'Nexi',
  NIMOCA = 'Nimoca',
  OREM = 'Onder Rembours',
  PASMO = 'Pasmo',
  PAYBACKPAY = 'Payback Pay',
  PAYBOX = 'Paybox',
  PAYCONIQ = 'Payconiq',
  PAYPAL = 'PayPal',
  PAYPAY = 'PayPay',
  PAYSEC = 'PaySec',
  PIN = 'PIN',
  POSTEPAY = 'Postepay',
  QRCODE = 'QR Code Payment',
  QUICPAY = 'QUICPay',
  RAKUTENEDY = 'Rakuten Edy',
  RAKUTENPAY = '楽天Pay',
  SAMSUNGPAY = 'Samsung Pay',
  SODEXO = 'Sodexo',
  SUGOCA = 'Sugoca',
  SUICA = 'Suica',
  SWISH = 'Swish',
  TICKETRESTAURANT = 'Ticket Restaurant',
  TOICA = 'Toica',
  TRAVELERSCHECK = "Traveler's Check",
  TSCUBIC = 'TS CUBIC',
  TWINT = 'Twint',
  UNIONPAY = 'China UnionPay',
  VEV = 'Via een verzekering',
  VISA = 'Visa',
  VISAELECTRON = 'Visa Electron',
  VOB = 'Vooruit betalen',
  VOUCHER = 'Voucher',
  VPAY = 'V PAY',
  WAON = 'WAON',
  WECHATPAY = 'WeChat Pay',
  WIRETRANSFER = 'Wire Transfer',
  Yucho_Pay = 'ゆうちょPay',
  ZELLE = 'Zelle',
  AuPay = 'auPay',
  DBarai = 'd払い ',
  Überweisung = 'Banküberweisung',
}

export enum PriceRange {
  UNSPECIFIED = 'Unspecified',
  ONE = '$',
  TWO = '$$',
  THREE = '$$$',
  FOUR = '$$$$',
}

export interface ReservationUrl {
  url?: string
  displayUrl?: string
  preferDisplayUrl?: boolean
}

export interface WebsiteUrl {
  url?: string
  displayUrl?: string
  preferDisplayUrl?: boolean
}

export interface ComplexVideo {
  url: string
  video?: string
  description?: string
}

export default interface Location {
  accessHours?: Hours
  appleActionLinks?: AppleActionLinks[]
  appleBusinessDescription?: string
  appleBusinessId?: string
  appleBusinessIdDqe?: string
  appleCompanyId?: string
  appleCompanyIdDqe?: string
  appleCoverPhoto?: Image
  bingParentLocation?: EntityReference
  bingRelationshipType?: BingRelationshipType
  bingWebsiteOverride?: string
  blackOwnedBusiness?: boolean
  brunchHours?: Hours
  covid19InformationUrl?: string
  covidMessaging?: string
  deliveryHours?: Hours
  deliveryUrl?: string
  dineInHours?: Hours
  driveThroughHours?: Hours
  facebookWebsiteOverride?: string
  fullyVaccinatedStaff?: boolean
  geomodifier?: string
  happyHours?: Hours
  holidayHoursConversationEnabled?: boolean
  kitchenHours?: Hours
  landingPageUrl?: string
  linkedInUrl?: string
  neighborhood?: string
  nudgeEnabled?: boolean
  onlineServiceHours?: Hours
  phoneticName?: string
  pickupAndDeliveryServices?: PickupAndDeliveryServices[]
  pickupHours?: Hours
  pinterestUrl?: string
  primaryConversationContact?: any
  proofOfVaccinationRequired?: boolean
  reviewResponseConversationEnabled?: boolean
  seniorHours?: Hours
  slug?: string
  takeoutHours?: Hours
  tikTokUrl?: string
  what3WordsAddress?: string
  yelpWebsiteOverride?: string
  youTubeChannelUrl?: string
  additionalHoursText?: string
  address: Address
  addressHidden?: boolean
  alternatePhone?: any
  androidAppUrl?: string
  associations?: string[]
  brands?: string[]
  description?: string
  hours?: Hours
  logo?: ComplexImage
  name: string
  categories?: any
  cityCoordinate?: Coordinate
  closed?: boolean
  c_aboutSectionBody?: string
  c_aboutSectionCTA?: C_aboutSectionCTA
  c_aboutSectionHeading?: string
  c_locationBrand?: C_locationBrand
  c_additionalHoursInfo?: string
  c_addressPrefix?: string
  c_addressSufix?: string
  c_allPagesCarouselNew?: Image[]
  c_appleName?: string
  c_armaniCasaURL?: string
  c_armaniDolciURL?: string
  c_armaniFioriURL?: string
  c_armaniHotelsURL?: string
  c_armaniLibriURL?: string
  c_armaniSilosURL?: string
  c_armaniSite?: C_armaniSite
  c_armaniURL?: string
  c_aXPagesURL?: string
  c_bookAnAppointment?: boolean
  c_bookAnAppointment1?: C_bookAnAppointment1
  c_bookAnAppointmentURL?: string
  c_bookAnAppointmentURLAX?: string
  c_brandDirectoryCountryListPageMetaDescription?: string
  c_brandDirectoryCountryListPageMetaTitle?: string
  c_brandDirectoryLocListPageMetaDescription?: string
  c_brandDirectoryLocListPageMetaTitle?: string
  c_brandsSectionHeading?: string
  c_categoriesSectionHeading?: string
  c_chatWithStore?: boolean
  c_checkAvailability?: boolean
  c_clickFromStore?: boolean
  c_collections?: C_collections[]
  c_countryISOCode?: string
  c_countryName?: string
  c_coverPhoto?: Image
  c_digitalServicesGroup1?: boolean
  c_digitalServicesGroup2?: boolean
  c_digitalServicesGroup3?: boolean
  c_directoryContinent?: C_directoryContinent
  dm_directoryParents_locations_directory?: EntityReference[]
  c_eA7URL?: string
  c_emporioArmaniJuniorURL?: string
  c_emporioArmaniURL?: string
  c_exchangeInStore?: boolean
  c_exchangeInStore1?: C_exchangeInStore1
  c_exemptFromETL?: boolean
  c_flagship?: boolean
  c_folderBrand?: C_folderBrand
  c_folderBrandValue?: string
  c_free1?: boolean
  c_free2?: boolean
  c_free3?: boolean
  c_free4?: boolean
  c_giftCardAccepted?: boolean
  c_giorgioArmaniURL?: string
  c_googleMapsURL?: string
  c_intentPageModuleName?: string
  c_internalName?: string
  c_isAGoogleBoutique?: boolean
  c_lINE?: C_lINE
  c_lINEID?: string
  c_linkedIntentPages?: EntityReference[]
  c_listingsName?: string
  c_localFullAddress?: string
  c_locationBrandLogoOverride?: Image
  c_locationBrands?: C_locationBrands[]
  c_locationCategories?: C_locationCategories[]
  c_locationCategoriesAX?: C_locationCategoriesAX[]
  c_locationGender?: C_locationGender[]
  c_locationGenderAX?: C_locationGenderAX[]
  c_locationName?: string
  c_locationServices?: C_locationServices[]
  c_locationServicesAX?: C_locationServicesAX[]
  c_madeToMeasure?: boolean
  c_madetoMeasureURL?: string
  c_madeToMeasureURLAX?: string
  c_marketplaceGroup1?: boolean
  c_marketplaceGroup2?: boolean
  c_marketplaceGroup3?: boolean
  c_menuPhotoUrls?: Image[]
  c_notificationBanner?: string
  c_oCSFull?: boolean
  c_omnichannelServicesDistributionList?: string[]
  c_omnichannelServicesEmail?: string[]
  c_openingDate?: string
  c_pageHeading?: string
  c_pageMetaDescription?: string
  c_pageMetaTitle?: string
  c_pagesURL?: string
  c_pickUpInStore1?: C_pickUpInStore1
  c_pickupInStore?: boolean
  c_primaryCTA1?: C_primaryCTA1
  c_primaryCTA2?: C_primaryCTA2
  c_primaryCTA3?: C_primaryCTA3
  c_productCategories?: string[]
  c_productGenfer?: string[]
  c_promos?: C_promos[]
  c_publishPage?: boolean
  c_relationship?: string
  c_reserveInStore?: boolean
  c_restaurantsCafesURL?: string
  c_returnInStore1?: C_returnInStore1
  c_returnInStore?: boolean
  c_servicesSectionHeading?: string
  c_shipFromStore?: boolean
  c_siteID?: string
  c_storeRelationship?: C_storeRelationship
  c_storeStatus?: string
  c_storeType?: string
  c_tailoring?: boolean
  c_temporaryClosureDates?: C_temporaryClosureDates
  c_whatsApp?: C_whatsApp
  c_wifi?: boolean
  firstPartyReviewPage?: any
  reviewGenerationUrl?: any
  defaultReviewInviteTemplate?: any
  displayCoordinate?: Coordinate
  dropoffCoordinate?: Coordinate
  emails?: string[]
  facebookEmail?: string
  facebookPageUrl?: string
  fax?: any
  featuredMessage?: FeaturedMessage
  photoGallery?: ComplexImage[]
  geocodedCoordinate?: Coordinate
  googleWebsiteOverride?: string
  instagramHandle?: string
  iosAppUrl?: string
  isoRegionCode?: string
  keywords?: string[]
  languages?: string[]
  localPhone?: any
  locationType?: LocationType
  mainPhone?: any
  menuUrl?: MenuUrl
  mobilePhone?: any
  orderUrl?: OrderUrl
  paymentOptions?: PaymentOptions[]
  phones?: any
  pickupCoordinate?: Coordinate
  priceRange?: PriceRange
  products?: string[]
  reservationUrl?: ReservationUrl
  routableCoordinate?: Coordinate
  services?: string[]
  shortName35?: string
  shortName64?: string
  specialities?: string[]
  id: string
  timezone?: any
  tollFreePhone?: any
  ttyPhone?: any
  twitterHandle?: string
  walkableCoordinate?: Coordinate
  websiteUrl?: WebsiteUrl
  yearEstablished?: number
  yextDisplayCoordinate?: Coordinate
  yextDropoffCoordinate?: Coordinate
  yextPickupCoordinate?: Coordinate
  yextRoutableCoordinate?: Coordinate
  yextWalkableCoordinate?: Coordinate
  videos?: ComplexVideo[]
}
