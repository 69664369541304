<script setup lang="ts">
import type { CheckoutAddressSidebarLogCardProps } from './CheckoutAddressSidebarLogCard.props'

const props = defineProps<CheckoutAddressSidebarLogCardProps>()
const emit = defineEmits<{
  (e: 'showOrEditAddress', value?: YextAddress): YextAddress
  (e: 'removeAddress'): void
}>()
const { country } = useRouteHelper()
const isJp = computed(() => country.toUpperCase() === 'JP')
const isUs = computed(() => country.toUpperCase() === 'US')
</script>
<template>
  <div class="flex flex-col gap-2">
    <div class="text-light-6">
      <div v-if="showCustomerFullName">
        <p v-if="address?.company">
          <span class="text-medium-5">{{ address?.company }}</span>
        </p>
        <p v-else>
          <span>{{ address?.first_name }}&nbsp;</span>
          <span>{{ address?.last_name }}</span>
        </p>
      </div>
      <p v-if="isJp">
        <span>{{ address.first_name_kanji }}&nbsp;</span>
        <span>{{ address.last_name_kanji }}</span>
      </p>
      <p v-if="!isJp">
        {{ address.line_1 }}
      </p>
      <p>
        <span v-if="address.zip_code && !isUs">{{ address.zip_code }}</span>
        <span v-if="address.city">{{ ' ' + address.city }}</span>
        <span v-if="address.state_code">, {{ address.state_code }} </span>
        <span v-if="address.country_code && !isJp && !isUs"
          >,
          {{ address.country_code }}
        </span>
        <span v-if="address.zip_code && isUs">, {{ address.zip_code }}</span>
      </p>
      <p v-if="isJp">
        <span v-if="address.line_1">{{ address.line_1 }}&nbsp;</span>
        <span v-if="address.notes">{{ address.notes }}</span>
      </p>
    </div>
    <div class="text-link-6 flex gap-2">
      <button
        class="underline"
        :aria-label="`${primaryCtaLabel} ${$ts('aria-labels.about')} ${address?.company ? address.company : address.line_1}`"
        aria-haspopup="dialog"
        @click.stop="emit('showOrEditAddress', infos)"
      >
        {{ primaryCtaLabel }}
      </button>
      <p v-if="showSecondaryCta">|</p>
      <button v-if="showSecondaryCta" @click.stop="emit('removeAddress')">
        {{ $ts('checkout.shipping.removeAddress') }}
      </button>
    </div>
  </div>
</template>
