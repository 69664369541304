import { C_folderBrand } from 'types/yext'

type MarkersIcons = 'pinGeneric' | 'pinGA' | 'pinEA' | 'pinAX' | 'pinCasa'

export interface StoreMap extends SearchApiResult {
  icon: `/images/map/${MarkersIcons}.svg`
  visible: boolean
  distance: number
}

export const useStoreLocator = () => {
  const { country, language } = useRouteHelper()
  const { cartItemsByBrand } = useCartUtilities()

  const selectedStore = useState<Address>()
  const selectedStoreDetails = useState<YextAddress>()
  const stores = useState<StoresVerticalResults | null>(
    `stores-map`,
    () => null
  )

  const filterFlags = {
    store: 'c_pickupInStore=true',
    boutique: 'c_isAGoogleBoutique=true',
  }

  const checkExistingStoreOrBoutique = async (
    {
      filterFor,
    }: {
      filterFor: 'store' | 'boutique' | false
    } = { filterFor: false }
  ) => {
    try {
      const filter = filterFor
        ? filterFlags[filterFor as keyof typeof filterFlags]
        : ''
      return await $fetch('/api/yext/getStoresAndBoutiques', {
        method: 'POST',
        body: { country, filter },
      })
    } catch (error) {
      console.error('Error fetching store data:', error)
    }
  }

  const centerMap = useState<Coordinates | undefined>(
    'centerMap',
    () => undefined
  )

  const setCenterMap = (coords: Coordinates) => {
    centerMap.value = coords
  }

  const brands = computed(() => {
    const keys = cartItemsByBrand.value.keys()
    return Array.from(keys).map((el: string) => {
      const key = el.replace('-', '_').toUpperCase()
      return {
        c_folderBrand: {
          $eq: C_folderBrand[key as keyof typeof C_folderBrand],
        },
      }
    })
  })

  const findStoresByLocation = async ({ coords }: { coords?: Coordinates }) =>
    await $fetch<StoresVerticalResults>('/api/yext/getStoresByLocation', {
      method: 'POST',
      query: {
        location: `${coords?.lat},${coords?.lng}`,
        language,
      },
      body: {
        $or: [...brands.value],
        $and: [{ 'address.countryCode': { $eq: country.toUpperCase() } }],
      },
    })

  const setStoreVisible = (idStore: string, visible: boolean) => {
    if (stores.value && stores.value.results.length) {
      stores.value = {
        ...stores.value,
        results:
          stores.value?.results?.map(store =>
            store.data.id === idStore
              ? {
                  ...store,
                  visible: visible,
                }
              : store
          ) ?? [],
      }
    }
  }

  const getIcon = (storeType?: C_folderBrand | null) => {
    const defaultIcon = '/images/map/pinGeneric.svg'
    if (!storeType) return defaultIcon
    const icons: Partial<Record<C_folderBrand, StoreMap['icon']>> = {
      'Giorgio Armani': '/images/map/pinGA.svg',
      'Emporio Armani': '/images/map/pinEA.svg',
      'Emporio Armani Junior': '/images/map/pinEA.svg',
      'Armani Exchange': '/images/map/pinAX.svg',
      'Armani/Casa': '/images/map/pinCasa.svg',
    }
    return icons[storeType] || defaultIcon
  }

  return {
    checkExistingStoreOrBoutique,
    selectedStore,
    selectedStoreDetails,
    stores,
    centerMap,
    setCenterMap,
    findStoresByLocation,
    setStoreVisible,
    getIcon,
  }
}
