const FORMAT_TO_MILES_VALUE = 0.62137

export const getKmOrMiles = (
  {
    distance,
    toMilesCondition,
  }: { distance: number; toMilesCondition?: boolean } = { distance: 0 }
) => {
  const kilometers = distance / 1000
  const distanceWithUnit = toMilesCondition
    ? kilometers * FORMAT_TO_MILES_VALUE
    : kilometers
  return distanceWithUnit.toFixed(1)
}
