<script setup lang="ts">
import type { CustomMarker } from '@design-system/components/Atoms/StoreLocator/AtomsStoreLocatorMap.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { checkoutStoreDetailsSidebar, checkoutPickupStoreSidebar } = SIDEBAR_IDS

defineProps<{ headerTitle?: string }>()

const emit = defineEmits<{
  'update:store-selected': []
  'update:stores-found': [value: google.maps.places.PlaceResult]
  'localize-address': [value: Coordinates]
}>()

const { ts } = useI18n()
const { country } = useRouteHelper()
const { openDialog, closeDialog } = useDialog()
const { isDialogOpen: isChildSidebarOpen } = useDialog(
  checkoutStoreDetailsSidebar
)
const { isDialogOpen: isPickUpSidebarOpen } = useDialog(
  checkoutPickupStoreSidebar
)
const { coords, error, resume, pause } = useGeolocation({ immediate: false })
const { stores, selectedStore, selectedStoreDetails, getIcon } =
  useStoreLocator()

watchDebounced(
  isPickUpSidebarOpen,
  () => {
    isPickUpSidebarOpen.value ? resume() : pause()
  },
  {
    debounce: 1000,
  }
)

const countryMapCenter = getCountryMapCenter(country)
const showMap = ref(false)
const gmapSelectedStoreId = ref('')

const mappedStores = computed((): Address[] =>
  stores.value?.results && stores.value?.results.length
    ? stores.value.results
        .map(({ data }) => ({
          id: data.id,
          business: true,
          company: data.c_pageHeading ?? data.c_internalName ?? data.name,
          first_name: 'N/A',
          last_name: 'N/A',
          country_code: data.address.countryCode ?? '',
          line_1: data.address.line1 ?? '',
          city: data.address.city ?? '',
          zip_code: data.address.postalCode ?? '',
          state_code: data.address.region ?? '',
          prefix: '',
          phone: data.mainPhone,
          coordinates: getCoordinates({
            lat: data.yextDisplayCoordinate?.latitude,
            lng: data.yextDisplayCoordinate?.longitude,
          }),
          storeCode: data.id,
        }))
        .filter(store =>
          showMap.value ? store.id === gmapSelectedStoreId.value : true
        )
    : []
)

const gmapMarkers = computed<CustomMarker[]>(
  () =>
    stores.value?.results?.map(({ data }) => ({
      id: data.id,
      customIcon: getIcon(data.c_folderBrand),
      position: getCoordinates({
        lat: data.yextDisplayCoordinate?.latitude,
        lng: data.yextDisplayCoordinate?.longitude,
      }),
    })) ?? []
)

const pickedStore = computed(() =>
  mappedStores.value.find(
    (store: Address) => store.id === gmapSelectedStoreId.value
  )
)

const storePlaceholder = computed<Address[]>(() =>
  mappedStores.value.filter(el => el.id === gmapSelectedStoreId.value)
)

const storesOrStoreWithMap = computed(() => {
  if (mappedStores.value.length) return mappedStores.value
  return showMap.value ? storePlaceholder.value : []
})

const isStoreSelected = computed(() =>
  gmapSelectedStoreId.value ? { primaryText: 'CONTINUE' } : undefined
)

const distanceUnit = computed(() =>
  countriesUsingMph.includes(country)
    ? ts('checkout.pickup.miles')
    : ts('checkout.pickup.km')
)

const gmapCenter = computed(() => {
  if (pickedStore.value) return pickedStore.value.coordinates

  const storePlaceholderCoords = storePlaceholder.value?.[0]?.coordinates
  if (storePlaceholderCoords) return storePlaceholderCoords

  if (stores.value?.locationBias)
    return {
      lat: stores.value?.locationBias.latitude,
      lng: stores.value?.locationBias.longitude,
    }

  return countryMapCenter
})

const toggleShowMap = () => {
  showMap.value = !showMap.value
  if (showMap.value) gmapSelectedStoreId.value = ''
}

const selectStore = () => {
  closeDialog(checkoutPickupStoreSidebar)

  if (mappedStores.value.length && pickedStore.value) {
    selectedStore.value = pickedStore.value
  }

  selectedStoreDetails.value = stores.value?.results.find(
    el => el.data.id === selectedStore.value.id
  )?.data as YextAddress

  emit('update:store-selected')
}

const selectStoreDetails = (address?: YextAddress) => {
  if (!address) return
  selectedStoreDetails.value = address
  openDialog(checkoutStoreDetailsSidebar)
}

const formatFromKmToMiles = (distance: number) => {
  const calculatedDistance = getKmOrMiles({
    distance,
    toMilesCondition: countriesUsingMph.includes(country),
  })
  return `${calculatedDistance} ${distanceUnit.value}`
}

const handleSearch = (value: google.maps.places.PlaceResult) =>
  emit('update:stores-found', value)

const handleLocalizeMe = () => {
  if (error.value) {
    alert(ts('checkout.shipping.geolocationFailedAlert'))
    return
  }

  emit('localize-address', {
    lat: coords.value.latitude,
    lng: coords.value.longitude,
  })
}

const handleMarkerClick = (marker: google.maps.Marker) => {
  gmapSelectedStoreId.value = marker.get('id')
}
</script>

<template>
  <OrganismsSidebarSlide
    :id="checkoutPickupStoreSidebar"
    :header-props="{
      titleText: headerTitle ?? ts('checkout.pickup.sidebarHeader'),
      hasCloseButton: true,
    }"
    :footer-props="isStoreSelected"
    :force-open="isChildSidebarOpen"
    @primary-click="selectStore"
    @sidebar-closed="gmapSelectedStoreId = ''"
  >
    <template #body>
      <div class="gap-lg md:gap-xl mt-1 flex flex-col">
        <InputsGoogleAutocomplete
          :action-text="$ts('localizeMe')"
          :restrict-to-country="country"
          show-remove-button
          @searched="handleSearch"
          @click-action-button="handleLocalizeMe"
        />
        <div class="gap-sm flex flex-col">
          <div class="flex justify-between">
            <span class="text-book-6 uppercase">
              {{ $ts('checkout.pickup.storeList') }}
            </span>
            <button class="text-link-6 underline" @click="toggleShowMap">
              {{
                showMap
                  ? $ts('checkout.pickup.viewList')
                  : $ts('checkout.pickup.viewMap')
              }}
            </button>
          </div>
          <div v-if="showMap" class="aspect-3/2">
            <AtomsStoreLocatorMap
              :center="gmapCenter"
              :markers="gmapMarkers"
              class="h-full w-full"
              @on-marker-click="handleMarkerClick"
            />
          </div>
          <InputsRadioWithSlot
            v-for="(store, index) in storesOrStoreWithMap"
            :id="store.id"
            :key="'store-' + index"
            v-model="gmapSelectedStoreId"
            :label="store.company ?? ''"
            :aria-label="`${store.company ?? ''} ${formatFromKmToMiles(
              stores?.results?.find((el: any) => el?.data?.id === store?.id)
                ?.distance ?? 0
            )}
            `"
            name="addresses"
            :value="store.id"
            is-always-expanded
          >
            <template #extra-label>
              <span class="text-light-5">
                {{
                  formatFromKmToMiles(
                    stores?.results?.find(
                      (el: any) => el?.data?.id === store?.id
                    )?.distance ?? 0
                  )
                }}
              </span>
            </template>
            <template #extra-content>
              <CheckoutAddressSidebarLogCard
                :infos="
                  stores?.results?.find((el: any) => el?.data?.id === store?.id)
                    ?.data as YextAddress
                "
                class="pl-7"
                :address="store"
                :primary-cta-label="$ts('checkout.pickup.seeDetails')"
                @show-or-edit-address="selectStoreDetails"
              />
            </template>
          </InputsRadioWithSlot>
        </div>
      </div>

      <CheckoutStoreDetailsSidebar
        :selected-store-details="selectedStoreDetails"
        :is-alpha-layer-transparent="true"
      />
    </template>
  </OrganismsSidebarSlide>
</template>
